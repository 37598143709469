<template>
  <div class="container">
    <admin-title></admin-title>
    <div class="top">
      <div class="t-content">
        <div class="left">
          <div class="num">
            <span class="title">WinC スカウトチケット数</span>
            <span class="now-num">{{topInfo.residueNum}}&nbsp;&nbsp;</span>
            <span class="total-num">/ {{ topInfo.totalNum }}</span>
          </div>
          <div class="date">
            <span class="title">購入日時</span>
            <span class="grey">{{topInfo.createTimeLabel}}</span>
          </div>
          <div class="date">
            <span class="title">送信期限</span>
            <span>{{topInfo.expirationDateLabel}}</span>
          </div>
        </div>
        <el-tooltip class="item" effect="dark" content="WinC Careerは学生一人ひとりに企業が向き合い、想いを伝えられるよう、一括送信はできない仕様です。" placement="bottom-end">
          <div class="right">
            <img src="../../assets/images/ae/ques_blue.png">
            <span>スカウトの想い</span>
          </div>
        </el-tooltip>

      </div>
    </div>

    <div class="bottom">
      <div class="instrument">ダッシュボード</div>

      <div class="card-list">
        <div class="item item-1">
          <div class="item-top">
            <span>配信数</span>
            <img src="../../assets/images/ae/arrow_right.png"/>
            <img src="../../assets/images/ae/mail.png">
          </div>
          <div class="item-bottom">
            <div>
              <p class="p-1">{{dashboard.totalSendNum}}</p>
              <p class="p-2">/ {{dashboard.totalPurchaseNum}}</p>
            </div>
            <p class="p-3">予約：{{dashboard.bookingNum}}　配信済：{{dashboard.sentNum}}</p>
          </div>
        </div>
        <div class="item item-2">
          <div class="item-top">
            <span>開封数</span>
            <img src="../../assets/images/ae/open_mail.png"/>
          </div>
          <div class="item-bottom">
            <p class="single-num">{{dashboard.openNum}}</p>
          </div>
        </div>

        <div class="item item-3">
          <div class="item-top">
            <span>応募数</span>
            <img src="../../assets/images/ae/hand.png"/>
          </div>
          <div class="item-bottom">
            <p class="single-num">{{dashboard.applicationNum}}</p>
          </div>
        </div>

      </div>

      <div class="table-box">
        <div class="table-top">送信一覧</div>
        <div class="table-content">
          <div class="table-c">
            <el-table
                :data="dashboard.sendList"
                height="18.2rem"
                style="width: 100%">
              <el-table-column
                  prop="sendDateLabel"
                  label="送信日時"
                  >
              </el-table-column>
              <el-table-column
                  prop="saveListName"
                  show-overflow-tooltip
                  label="保存リスト/送信者ID">
              </el-table-column>
              <el-table-column
                  prop="userCnt"
                  label="配送数">
              </el-table-column>
              <el-table-column
                  prop="tempName"
                  show-overflow-tooltip
                  label="テンプレ名">
              </el-table-column>
              <el-table-column
                  prop="entryTitle"
                  show-overflow-tooltip
                  label="セット原稿">
              </el-table-column>
              <el-table-column width="300%" label="">
                <template slot-scope="scope">
                  <div class="buttons">
                    <div class="button edit" v-if="scope.row.isSent == 0" @click="handleEdit(scope.$index, scope.row)">編集</div>
                    <div class="button delete" v-if="scope.row.isSent == 0" @click="handleDelete(scope.$index, scope.row)">削除</div>
<!--                    <div class="button detail" v-if="nowTime >= scope.row.sendDateLabel" @click="handleDetail(scope.$index, scope.row)">詳細</div>-->
                  </div>
<!--                  <template v-else>-->
<!--                    <div class="buttons">-->
<!--                      -->
<!--                    </div>-->

<!--                  </template>-->
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>

      <div class="table-box">
        <div class="table-top">チケット購入履歴</div>
        <div class="table-content">
          <div class="table-c">
            <el-table
                :data="dashboard.historyList"
                height="18.2rem"
                style="width: 100%">
              <el-table-column
                  prop="createTimeLabel"
                  label="購入日時"
              >
              </el-table-column>
              <el-table-column
                  prop="residueNum"
                  label="総残数"
              >
                <template slot-scope="scope">
                  <p v-if="scope.row.isEnable == 1" class="table-slot-you"><span>{{scope.row.residueNum}}</span> /{{scope.row.ticketNum}}</p>
                  <p v-else class="table-slot-wu"><span>{{scope.row.residueNum}}</span> /{{scope.row.ticketNum}}</p>
                </template>
              </el-table-column>
              <el-table-column
                  prop="expirationDateLabel"
                  label="送信期限"
              >
              </el-table-column>
              <el-table-column>
                <template #header>
                  <div class="select">
                    <el-select class="select" style="width:8rem;" @change="selectItem" v-model="value" placeholder="状態">
                      <el-option value="" label=""></el-option>
                      <el-option :value="1" label="有効"></el-option>
                      <el-option :value="0" label="無効"></el-option>
                    </el-select>
                  </div>
                </template>

                <template slot-scope="scope">

                  <p class="you" v-if="scope.row.isEnable == 1">有効</p>
                  <p class="wu" v-else>無効</p>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LOCAL_STORAGE_KEY } from "@/constants/common.js";
export default {
  name: "ae",

  data() {
    let nowTime = this.$moment().format('YYYY/MM/DD HH:mm');
    // console.log(nowTime)
    return {
      options: [{
        value: '1',
        label: ''
      }, {
        value: 1,
        label: '有効'
      }, {
        value: 0,
        label: '無効'
      }],
      value: "",

      topInfo: {},

      dashboard: {
        sendList: [],
        historyList: []
      },

      tableData: [],


      nowTime: nowTime,
    }
  },

  mounted() {


    this.userInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY.USER_INFO));



    this.getTopInfo();
    this.getDashboard();
    // this.getList();
  },

  methods: {
    async getTopInfo() {
      let res = await this.$axios({url: `/compScout/top`, data: {token: this.userInfo.token}, method: "post"});
      if(res.code == '000' && res.data) {
        this.topInfo = res.data;
      }
    },

    selectItem(e) {
      this.value = e;
      if(e === "") {
        this.dashboard.historyList = this.tableData;
      } else {
        this.dashboard.historyList = this.tableData.filter((item) => {
          return item.isEnable == e;
        })
      }


      this.$forceUpdate()
    },

    async getDashboard() {
      let res = await this.$axios({url: `/compScout/dashboard`, data: {compId: this.userInfo.compId}, method: "post"});
      if(res.code == '000') {
        this.dashboard = res.data;
        this.tableData = res.data.historyList;

      }
    },

    handleEdit(index, row) {
      console.log(index, row);

      this.$router.push('/index/deliverySettings?id=' + row.id + '&type=1')

    },

    handleDetail(index, row) {
      this.$router.push('/index/deliverySettings?id=' + row.id + '&type=2')
    },

    handleDelete(index, row) {
      let that=this;

      this.$confirm('送信予約を削除してよろしいですか？', '', {
        confirmButtonText: 'はい',
        cancelButtonText: 'キャンセル',
        center: true
      }).then(() => {
        that.deleteItem(row.id);

      }).catch(() => {

      });
    },

    async deleteItem(id) {

      let res = await this.$axios({url: `/compScout/sendMg/delete`, data: {id: id}, method: "post"});
      if(res.code == '000') {
        this.$message({
          type: 'success',
          message: res.message
        });
        this.getDashboard();
      }

    },
  }
}
</script>

<style scoped>
.select {

}

.select /deep/ .el-input--suffix .el-input__inner {
  height: 2rem;
}

/*.select /deep/ .el-input--suffix .el-input__inner {*/
/*  width: 3.75rem;*/
/*  height: 1.5625rem;*/
/*  line-height: 1.5625rem;*/
/*  border-radius: .3906rem;*/
/*  border: 0.0586rem solid #A3A3A3;*/
/*  padding-right: 0;*/
/*  padding-left: 0;*/

/*  color: #A3A3A3;*/
/*  text-align: right;*/
/*  font-family: Inter;*/
/*  font-size: .7813rem;*/
/*  font-style: normal;*/
/*  font-weight: 500;*/

/*  text-align: left;*/
/*  !*line-height: normal;*!*/
/*}*/


.top {
  height: 7rem;
  background: #15B1CE;

  display: flex;
  align-items: center;
  justify-content: center;
}

.top .t-content {
  width: 83rem;
  height: 3.9rem;
  background: #FFFFFF;
  border-radius: .6rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.t-content .left {
  display: flex;
  align-items: center;

}

.t-content .left .title {
  font-size: 1.25rem;

  font-style: normal;
  font-weight: 500;
  color: #000000;
}

.t-content .left .num .title {
  margin-left: .8rem;
  margin-right: 1.9rem;
}

.t-content .left .date .title {
  margin-left: 3.841rem;
  margin-right: 1.875em;
}

.t-content .left>div {
  display: flex;
  align-items: center;
}

.t-content .left .num .now-num {
  font-size: 1.9rem;
  color: #15B1CE;

  font-style: normal;
  font-weight: 700;
}

.t-content .left .num .total-num {
  color: #A3A3A3;

  font-style: normal;
  font-size: 1.3rem;
  margin-top: .4rem;
}

.grey {
  color: #A3A3A3;
}

.t-content .left .date {
  font-size: 1.3rem;

  font-style: normal;
  font-weight: 500;

  color: #15B1CE;
}

.t-content .right {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: .744rem;
}

.t-content .right img {
  width: .9375rem;
  height: .9375rem;
  flex-shrink: 0;
  margin-right: .391rem;
}

.t-content .right span {
  color: #25A0F9;
  text-align: center;
  font-family: Inter;
  font-size: .9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}



.bottom {
    padding: 0 3.125rem;
  }

  .bottom .instrument {
    width: 100%;
    padding-bottom: .7rem;
    border-bottom: 1px solid #A2A2A2;

    font-size: 1.1rem;
    margin-top: 3.4rem;


    font-style: normal;
    font-weight: 600;


    color: #424242;
  }

  .card-list {
    margin-top: 3.1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .card-list .item {
    width: 24.3rem;
    height: 12.7rem;
    background: #FFFFFF;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    overflow: hidden;
  }

  .card-list .item .item-top {
    width: 100%;
    display: flex;
    align-items: center;
    height: 3.6rem;
    background-color: rgb(21,177,206);
  }

  .card-list .item .item-top span {
    font-size: 1.3rem;

    font-style: normal;
    font-weight: 700;
    /* identical to box height */
    color: #FFFFFF;

    margin: 0 .6rem 0 1.2rem;
  }

  .card-list .item-1 img:nth-child(2) {
    width: 1rem;
    margin-right: .2rem;
    /*display: block;*/
  }

  .card-list .item-1 img:nth-child(3) {
    width: 1.5rem;
  }

  .card-list .item-2 img {
    width: 1.5rem;
  }

  .card-list .item-3 img {
    width: 1.2rem;
  }

  .card-list .item .item-bottom {
    position: relative;
    height: 9.1rem;
  }

.card-list .item-1 .item-bottom>div {
  display: flex;
  align-items: baseline;
  justify-content: center;
}

  .card-list .item-1 .item-bottom .p-1 {
    font-size: 5rem;


    font-style: normal;
    font-weight: 700;
    /*line-height: 5.8rem;*/
    /*top: 0;*/
    /*left: 5.3rem;*/
    /*position: absolute;*/
    color: #222222;
  }

  .card-list .item-1 .item-bottom .p-2 {
    font-size: 1.9rem;
    /*top: 3.3rem;*/
    /*right: 5rem;*/
    /*position: absolute;*/


    font-style: normal;
    font-weight: 500;
    /*line-height: 33px;*/
    /* identical to box height */


    color: #222222;
  }

  .card-list .item-1 .item-bottom .p-3 {
    width: 100%;
    text-align: center;
    left: 0;
    bottom: 1rem;
    position: absolute;
    font-size: 1.1rem;

    font-style: normal;
    font-weight: 500;

    color: #222222;

  }

  .card-list .item .item-bottom .single-num {
    font-size: 5rem;


    font-style: normal;
    font-weight: 700;
    line-height: 5.8rem;
    top: 0;
    left: 9.2rem;
    position: absolute;
    color: #222222;
  }

  .table-box {
    width: 100%;
    height: 27.7rem;
    background: #FFFFFF;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    overflow: hidden;
    margin: 3.1rem 0;
  }

  .table-box .table-top {
    height: 3.6rem;
    background: rgb(21,177,206);
    font-size: 1.3rem;

    font-style: normal;
    font-weight: 700;

    color: #FFFFFF;
    box-sizing: border-box;
    padding: .9rem 0 0 1.2rem;
  }

  .table-box .table-content {
    height: 24.1rem;
    width: 100%;
    padding: 1.2rem;
    box-sizing: border-box;
  }

  .table-box .table-content .table-c {
    height: 100%;
    overflow: auto;

    border: 1px solid #DCDCDC;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 1.2rem 1.2rem 1.6rem;
  }

  .container /deep/ .el-table th.el-table__cell.is-leaf {
    border-bottom: 0.5px solid #A2A2A2;
    padding-bottom: 1.4rem;
  }

  .container /deep/ .el-table td.el-table__cell {
    border-bottom: none;
  }

  .container /deep/ .el-table .el-table__cell {
    padding: 0;
  }

  .container /deep/ .el-table th.el-table__cell > .cell {
    padding: 0;

    font-style: normal;
    font-weight: 500;
    font-size: .8rem;
    /*line-height: 12px;*/

    color: #424242;

  }

  .container /deep/ .el-table .cell {
    padding: 0;
    padding-right: 1rem;
    font-size: .9rem;


    font-style: normal;
    font-weight: 500;
    /*line-height: 15px;*/
    /* identical to box height */

    line-height: normal;
    margin: .8rem 0;
    color: #424242;
  }

  .container /deep/ .el-table td.el-table__cell:last-child .cell {
    padding: 0 !important;
    /*text-align: center;*/
  }

  .container /deep/ .el-table tr {
    background: white !important;
  }
  .container /deep/  tbody tr:hover > td {
    background-color: transparent;
  }


  .container /deep/ .el-table tbody tr:hover > td {
    background-color: transparent !important;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .buttons .button {
    margin-right: 1.2rem;
  }

  .button {
    width: 5.1rem;
    min-width: 5.1rem;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    font-size: .9rem;
    border-radius: 5px 5px 5px 5px;
    opacity: 1;
    border: 1px solid transparent;


    font-style: normal;
    font-weight: 500;
    cursor: pointer;
  }

  .edit {
    color: #3CB55E;
    border-color: #3CB55E;
  }

  .detail {
    color: #A3A3A3;
    border-color: #A3A3A3;
  }

  .delete {
    color: #F35050;
    border-color: #F35050;
  }

  .table-box .table-content .table-c ::-webkit-scrollbar
  {
    width: .6rem;
    height: 3.9rem;
    background: transparent;
    /*background-color: red;*/
    /*background-color: #DADADA;*/
  }

  .table-box .table-content .table-c ::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0);
    border-radius: 10px;
    background-color: white;
  }

  .table-box .table-content .table-c ::-webkit-scrollbar-thumb
  {
    /*border-radius: 10px;*/
    background-color: #DADADA;
    border-radius: 16px 16px 16px 16px;
  }

  .table-slot-you span {
    color: #15B1CE;
    font-weight: 500;
  }

.table-slot-wu span {
  color: #424242;
  font-weight: 700;
}

.you {
  color: #3CB55E;
}

.wu {
  color: #A3A3A3;
}

.container /deep/ .el-select .el-input .el-select__caret {
  display: flex;
  align-items: center;
  justify-content: center;
}

  /*.top-text {*/
  /*  font-size: 1.3rem;*/


  /*  font-style: normal;*/
  /*  font-weight: 700;*/
  /*  !* identical to box height *!*/
  /*  color: #424242;*/
  /*  border-top: 1px transparent solid;*/
  /*}*/

  /*.top-text p {*/
  /*  margin: 1.3rem 0 0 3.1rem;*/
  /*}*/
</style>
